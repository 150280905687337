import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '@keytrade/components-button';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import { Link as SCLink } from '@keytrade/components-link';
import { FormSpacing } from '@keytrade/components-formspacing';
import { InputText } from '@keytrade/components-inputtext';
import { InputPhoneNumber } from '@keytrade/components-inputphonenumber';
import { SelectDropdown } from '@keytrade/components-selectdropdown';
import { InfoBox } from '@keytrade/components-infobox';
import { Paragraph } from '@keytrade/components-paragraph';
import { validation } from '@keytrade/functions';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';
import { LoaderSpinner } from '@keytrade/components-loader';

import Page from '@/components/Page';
import BlockWrapper from '@/components/BlockWrapper';
import Container from '@/components/Container';
import Title from '@/components/Title';
import { generateSlug } from '@/utils/SlugUtils';
import Link from '@/components/Link';
import useTranslations from '@/hooks/useTranslations';
import translationsKeys from '@/intl/en.json';
import { Link as InlineLink } from '@keytrade/components-link';

const { list: colors } = config.colors;

const allowedFileTypes = ['doc', 'docx', 'pdf'];

const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 34px;
  flex-wrap: wrap;
`;

const Hr = styled.hr`
  margin: 48px 0;
  border-top: 1px solid ${colors.BlueFog};
  border-bottom: none;
`;

const InfoBoxText = styled.span`
  font-size: 1.4rem;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
`;

const LoaderWrapper = styled.div`
  margin-left: 1.6rem;

  div {
    padding: 0;
  }
`;

const OTHER_CHANNEL_CODE = '7';

const langsKeys = Object.keys(translationsKeys.languages);
const channelsKeys = Object.keys(translationsKeys.jobs.application.channels);

const DATE_ALLOWED_CHAR_REGEX = /[^0-9\/]/g;
const DATE_REGEX =
  /^(0[1-9]|[12][0-9]|3[01])(\/)(1[0-2]|0[1-9])\2(19[0-9]{2}|20[0-9]{2})$/;

const getMpleoFormattedDate = (dateToFormat = '') => {
  if (!dateToFormat || typeof dateToFormat !== 'string') {
    return null;
  }

  const dateParts = dateToFormat.split('/');

  const parsedDate = new Date(
    parseInt(dateParts[2]),
    parseInt(dateParts[1]) - 1, // Month is 0-based
    parseInt(dateParts[0]),
  );

  // Check if we have an "Invalid Date" date instance
  if (parsedDate instanceof Date && isNaN(parsedDate as any)) {
    return null;
  }

  // Even if swagger displays an ISO date, we use this format because
  // MPLEO backoffice doesn't convert back ISO date to local date.
  // YYYY-MM-DD format prevents the birthdate to be set 1 day before user input.
  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
};

const JobApplicationTemplate: React.FC = (props) => {
  const { t, to } = useTranslations();
  const formRef = useRef<HTMLFormElement>(null);
  const data = props.data;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [missingFirstName, setMissingFirstName] = useState(false);
  const [lastName, setLastName] = useState('');
  const [missingLastName, setMissingLastName] = useState(false);
  const [email, setEmail] = useState('');
  const [contactLanguage, setContactLanguage] = useState('');
  const [missingContactLanguage, setMissingContactLanguage] = useState(false);
  const [birthdate, setBirthdate] = useState('');
  const [invalidBirthdate, setInvalidBirthdate] = useState(false);
  const [missingBirthdate, setMissingBirthdate] = useState(false);
  const [missingEmail, setMissingEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneWithPrefix, setPhoneWithPrefix] = useState('');
  const [finalPhone, setFinalPhone] = useState('');
  const [missingPhone, setMissingPhone] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState('BE');
  const [resume, setResume] = useState(false);
  const [missingResume, setMissingResume] = useState(false);
  const [invalidResume, setInvalidResume] = useState(false);
  const [uploadPendingResume, setUploadPendingResume] = useState(false);
  const [guidResume, setGuidResume] = useState();
  const [coverLetter, setCoverLetter] = useState(false);
  const [invalidCoverLetter, setInvalidCoverLetter] = useState(false);
  const [uploadPendingCoverLetter, setUploadPendingCoverLetter] =
    useState(false);
  const [guidCoverLetter, setGuidCoverLetter] = useState();
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [channelCode, setChannelCode] = useState('');
  const [missingChannelCode, setMissingChannelCode] = useState(false);
  const [otherChannel, setOtherChannel] = useState('');
  const [missingOtherChannel, setMissingOtherChannel] = useState(false);
  const [otherChannelIsTooLong, setOtherChannelIsTooLong] = useState(false);

  const langs = langsKeys.map((l) => to(`languages.${l}`, l));
  const channels = channelsKeys.map((l) =>
    to(`jobs.application.channels.${l}`, l),
  );

  const jobData = data.allVacanciesJson.nodes.find(
    (job) => job.languageId === props.pageContext.locale,
  );

  data.contentfulPage = {};
  data.contentfulPage.node_locale = props.pageContext.locale;
  data.contentfulPage.seoMetadata = {
    seoTitle: `${t('jobs.application.seo_title')} ${jobData.title}`,
    seoDescription: {
      seoDescription: `${t('jobs.application.seo_description')} ${jobData.title
        } - Keytrade Bank`,
    },
  };

  const getLanguageNodeForLocale = (data, locale) => {
    const vacancy = data.find((job) => job.languageId === locale);
    const applySlug = props?.pageContext?.applySlugs?.[locale];
    const isPageMissing = !vacancy || !applySlug;

    return [
      {
        node_locale: locale,
        slug: isPageMissing ? '404' : `job/${jobData.code}/${applySlug}`,
      },
    ];
  };

  data.allLanguages = {};
  data.allLanguages.nodes = [
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'en'),
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'fr'),
    ...getLanguageNodeForLocale(data.allVacanciesJson.nodes, 'nl'),
  ];

  // We have an issue on Android with the maskedInput,
  // the carret goes to the end of the mask after 1 char.
  // This a quick workaround for this issue, with some caveats
  // related to the fact the input has an internal state.
  const onBirthdateChange = (value: string) => {
    let newValue = value.replace(DATE_ALLOWED_CHAR_REGEX, '');
    const isFilling = newValue.length > birthdate.length;

    if (isFilling && (newValue.length === 2 || newValue.length === 5)) {
      newValue = `${newValue}/`;
    }

    setBirthdate(newValue);
  };

  const handleBirthdateValidation = () => {
    if (!birthdate) {
      if (invalidBirthdate) {
        setInvalidBirthdate(false);
      }

      return;
    }

    if (DATE_REGEX.test(birthdate) && getMpleoFormattedDate(birthdate)) {
      setInvalidBirthdate(false);
    } else {
      setInvalidBirthdate(true);
    }
  };

  const handleEmailValidation = () => {
    if (validation.email.isValid(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  const handlePhoneNumberValidation = () => {
    if (phoneWithPrefix.replace(' ', '').match(/^[+]{1}[0-9+]{9,15}$/)) {
      setInvalidPhone(false);
    } else {
      setInvalidPhone(true);
    }
  };

  const getUploadInProgress = () =>
    (resume && uploadPendingResume) ||
    (coverLetter && uploadPendingCoverLetter);

  const uploadFile = (
    e: React.ChangeEvent,
    field: 'resume' | 'coverLetter',
  ) => {
    if (!e.target.files[0]) {
      return;
    } else if (
      // We do a simple check on the file name and file size, not very strong but the backend will reject any disallowed files anyways
      !allowedFileTypes.includes(e.target.files[0].name.split('.').pop()) ||
      e.target.files[0].size > 10000000
    ) {
      if (field === 'resume') {
        setInvalidResume(true);
      } else {
        setInvalidCoverLetter(true);
      }
      return;
    } else {
      if (field === 'resume') {
        setResume(true);
        setMissingResume(false);
        setInvalidResume(false);
        setUploadPendingResume(true);
      } else {
        setCoverLetter(true);
        setInvalidCoverLetter(false);
        setUploadPendingCoverLetter(true);
      }
    }

    const formData = new FormData();
    formData.append('selectedFile', e.target.files[0], e.target.files[0].name);
    formData.append('module', 'careers');

    axios
      .post(process.env.GATSBY_UPLOAD_API, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const guid = res.data.id.replace('{', '').replace('}', '');
        if (field === 'resume') {
          setGuidResume(guid);
          setUploadPendingResume(false);
        } else {
          setGuidCoverLetter(guid);
          setUploadPendingCoverLetter(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFormSubmitError(true);
      });
  };

  const hasFieldsInErrorStatus = () => {
    let hasError = false;

    if (!firstName) {
      setMissingFirstName(true);
      hasError = true;
    }
    if (!lastName) {
      setMissingLastName(true);
      hasError = true;
    }
    if (!email) {
      setMissingEmail(true);
      hasError = true;
    }
    if (!resume) {
      setMissingResume(true);
      hasError = true;
    }
    if (!channelCode) {
      setMissingChannelCode(true);
      hasError = true;
    }
    if (channelCode === OTHER_CHANNEL_CODE && !otherChannel) {
      setMissingOtherChannel(true);
      hasError = true;
    }
    if (channelCode === OTHER_CHANNEL_CODE && otherChannel?.length > 100) {
      setOtherChannelIsTooLong(true);
      hasError = true;
    }
    if (!phoneWithPrefix || !phone || !finalPhone) {
      setMissingPhone(true);
      hasError = true;
    }
    if (!contactLanguage) {
      setMissingContactLanguage(true);
      hasError = true;
    }

    return hasError;
  };

  const hasInvalidFields = () =>
    !firstName ||
    !lastName ||
    !email ||
    invalidEmail ||
    !resume ||
    invalidResume ||
    invalidCoverLetter ||
    !channelCode ||
    (channelCode === OTHER_CHANNEL_CODE && !otherChannel) ||
    !phone ||
    missingPhone ||
    !phoneWithPrefix ||
    !finalPhone ||
    invalidPhone ||
    missingContactLanguage;

  const submitForm = (e: React.UIEvent) => {
    e.preventDefault();

    if (e.detail > 1) return;

    if (
      hasFieldsInErrorStatus() ||
      hasInvalidFields() ||
      getUploadInProgress()
    ) {
      formRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    } else {
      setIsSubmitting(true);

      axios
        .post(
          `${process.env.GATSBY_API_ORIGIN}/node/backend/cldprxy/chancloud/careers/keytrade/jobs/applications`,
          {
            vacancyCode: props.pageContext.job_id,
            firstname: firstName,
            lastname: lastName,
            phone: finalPhone,
            email,
            address: {
              country: countryOfResidence,
            },
            authorizedKeepData: true,
            birthdate: getMpleoFormattedDate(birthdate),
            language: contactLanguage,
            channelCode,
            otherChannel: otherChannel && otherChannel.slice(0, 100),
          },
        )
        .then((response) => {
          if (response.status === 201) {
            axios
              .post(
                `${process.env.GATSBY_API_ORIGIN}/node/backend/cldprxy/chancloud/v1/careers/register-upload`,
                {
                  code: response.data.code,
                  guidByFileType: {
                    CV: guidResume,
                    MotivationLetter: guidCoverLetter,
                  },
                  lang: props.pageContext.locale,
                },
              )
              .then((res) => {
                if (res.status === 200) {
                  setFormSubmitSuccess(true);
                  window.scrollTo(0, 0);
                } else {
                  throw new Error(`HTTP error! status: ${res.status}`);
                }

                setIsSubmitting(false);
              });
          }
        })
        .catch((error) => {
          setFormSubmitError(true);
        });
    }
  };

  if (
    !data.allContentfulJobListingBlock ||
    !data.allContentfulJobListingBlock.nodes ||
    !data.allContentfulJobListingBlock.nodes.length
  ) {
    // If we can't determine the privacy policy link (or if we're on the empty env)
    // best to render nothing at all instead of a form without policy link
    // seeing as that may constitute as a GDPR violation.
    return <></>;
  }

  const privacyPolicyLink = data.allContentfulJobListingBlock.nodes.find(
    (block) => block.node_locale === props.pageContext.locale,
  ).privacyPolicyLink;

  const backLink = `${generateSlug(
    props.pageContext.jobListingPage,
  )}#jobListing`;

  const uploadInProgress = getUploadInProgress();
  let resumeLabel = t('jobs.application.upload_resume');

  if (uploadPendingResume) {
    resumeLabel += ` (${t('jobs.application.upload_in_progress')})`;
  }

  let coverLetterLabel = t('jobs.application.upload_cover_letter');

  if (uploadPendingCoverLetter) {
    coverLetterLabel += ` (${t('jobs.application.upload_in_progress')})`;
  }

  useEffect(() => {
    const customText = phoneWithPrefix.includes(' 0')
      ? phoneWithPrefix.replace(' 0', '')
      : phoneWithPrefix;
    setFinalPhone(customText);
  }, [phoneWithPrefix]);

  let otherChannelError = null;

  if (channelCode === OTHER_CHANNEL_CODE) {
    if (missingOtherChannel) {
      otherChannelError = t('missing_field');
    } else if (otherChannelIsTooLong) {
      otherChannelError = t('jobs.application.channelCode.otherTooLongError');
    }
  }

  return (
    <Page data={props.data} {...props}>
      <BlockWrapper color='White'>
        <Container narrow>
          <BreadCrumb>
            <SCLink
              isSemiBold
              render={() => (
                <GatsbyLink to={backLink}>
                  {t('jobs.application.job_openings')}
                </GatsbyLink>
              )}
            />

            <Icon
              icon='icn_arrowRight'
              size='12px'
              color={colors.GreyWarm}
              margin='0 14px'
            />
            <SCLink
              isSemiBold
              render={() => (
                <GatsbyLink to={props.pageContext.jobDetailPageURL}>
                  {jobData.title}
                </GatsbyLink>
              )}
            />
          </BreadCrumb>
          <Title level='h1' size='xl' margin='0 0 72px'>
            {`${t('jobs.application.title')} : ${jobData.title}`}
          </Title>
          {formSubmitSuccess ? (
            <>
              <InfoBox type='success'>
                {t('jobs.application.submit_success')}
              </InfoBox>
              <Paragraph margin='3.2rem 0 0'>
                {t('jobs.application.submit_success_2')}
              </Paragraph>
            </>
          ) : (
            <form ref={formRef}>
              <FormSpacing>
                <InputText
                  label={t('jobs.application.first_name')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onFocus={() => setMissingFirstName(false)}
                  errorMessage={missingFirstName && t('missing_field')}
                />
                <InputText
                  label={t('jobs.application.last_name')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onFocus={() => setMissingLastName(false)}
                  errorMessage={missingLastName && t('missing_field')}
                />
                <InputText
                  type='email'
                  label={t('jobs.application.email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setMissingEmail(false)}
                  onBlur={handleEmailValidation}
                  errorMessage={
                    missingEmail
                      ? t('missing_field')
                      : invalidEmail
                        ? t('invalid_email')
                        : null
                  }
                />
                <InputPhoneNumber
                  label={t('jobs.application.phone')}
                  value={phone}
                  onPhoneNumberChange={(number) => setPhone(number)}
                  onChange={(number) => setPhoneWithPrefix(number)}
                  onFocus={() => {
                    setMissingPhone(false);
                    setInvalidPhone(false);
                  }}
                  onBlur={handlePhoneNumberValidation}
                  errorMessage={
                    missingPhone
                      ? t('missing_field')
                      : invalidPhone
                        ? t('invalid_phone')
                        : null
                  }
                />
                <SelectDropdown
                  isSearchable
                  label={t('jobs.application.country')}
                  options={props.pageContext.countries}
                  value={countryOfResidence}
                  onChange={(value) => {
                    setCountryOfResidence(value);
                  }}
                />
                <SelectDropdown
                  isSearchable
                  label={t('jobs.application.contactLanguage')}
                  options={langs}
                  value={contactLanguage}
                  onChange={(value) => {
                    setMissingContactLanguage(false);
                    setContactLanguage(value);
                  }}
                  language={props.pageContext.locale}
                  errorMessage={missingContactLanguage && t('missing_field')}
                />
                <InputText
                  type='text'
                  inputmode='numeric'
                  placeholder={t('jobs.application.date_placeholder')}
                  label={t('jobs.application.birthdate')}
                  value={birthdate}
                  onChange={(e) => onBirthdateChange(e.target.value)}
                  onFocus={() => setMissingBirthdate(false)}
                  onBlur={handleBirthdateValidation}
                  errorMessage={
                    missingBirthdate
                      ? t('missing_field')
                      : invalidBirthdate
                        ? t('invalid_birthdate')
                        : null
                  }
                  isOptional
                  language={props.pageContext.locale}
                />
                <InputText
                  type='file'
                  label={resumeLabel}
                  language={props.pageContext.locale}
                  description={t('jobs.application.upload_restrictions')}
                  onChange={(e) => uploadFile(e, 'resume')}
                  onFileRemove={() => {
                    setResume(false);
                    setInvalidResume(false);
                    setUploadPendingResume(false);
                  }}
                  errorMessage={
                    missingResume
                      ? t('missing_field')
                      : invalidResume
                        ? t('jobs.application.upload_restrictions')
                        : null
                  }
                  removeAccessibilityLabel='Remove file'
                />
                <InputText
                  type='file'
                  label={coverLetterLabel}
                  language={props.pageContext.locale}
                  description={t('jobs.application.upload_restrictions')}
                  onChange={(e) => uploadFile(e, 'coverLetter')}
                  onFileRemove={() => {
                    setCoverLetter(false);
                    setInvalidCoverLetter(false);
                    setUploadPendingCoverLetter(false);
                  }}
                  errorMessage={
                    invalidCoverLetter &&
                    t('jobs.application.upload_restrictions')
                  }
                  removeAccessibilityLabel='Remove file'
                  isOptional
                />
                <SelectDropdown
                  name='channelCode'
                  label={t('jobs.application.channelCode.label')}
                  options={channels}
                  value={channelCode}
                  onChange={(value) => {
                    setMissingChannelCode(false);
                    setChannelCode(value);
                  }}
                  errorMessage={missingChannelCode && t('missing_field')}
                />
                {channelCode === OTHER_CHANNEL_CODE && (
                  <InputText
                    name='otherChannel'
                    label={t('jobs.application.channelCode.other')}
                    value={otherChannel}
                    onChange={(e) => {
                      setMissingOtherChannel(false);
                      setOtherChannelIsTooLong(e.target.value.length > 100);
                      setOtherChannel(e.target.value);
                    }}
                    errorMessage={otherChannelError}
                  />
                )}
              </FormSpacing>
              <Hr />
              <InfoBox type='disclaimer'>
                <InfoBoxText>
                  {t('jobs.application.privacy_disclaimer_1')}
                  <br />
                  <br />
                  {t('jobs.application.privacy_disclaimer_2')}
                  <br />
                  {t('jobs.application.privacy_disclaimer_3')}
                  <InlineLink
                    href={`mailto:${t('jobs.application.privacy_disclaimer_3_link')}`}
                  >
                    {t('jobs.application.privacy_disclaimer_3_link')}
                  </InlineLink>
                  <br />
                  <br />
                  {t('jobs.application.privacy_disclaimer_4', {
                    here: (
                      <Link inline {...privacyPolicyLink}>
                        {t('jobs.application.here')}
                      </Link>
                    ),
                  })}
                </InfoBoxText>
              </InfoBox>
              {formSubmitError && (
                <InfoBox type='error' margin='2rem 0 0'>
                  <InfoBoxText>{t('api_error')}</InfoBoxText>
                </InfoBox>
              )}
              {uploadInProgress && (
                <InfoBox autoClose={false} type='information' margin='2rem 0 0'>
                  <InfoBoxText>
                    {t('jobs.application.upload_pending')}
                  </InfoBoxText>
                </InfoBox>
              )}
              <FormFooter>
                <Button
                  disabled={uploadInProgress || isSubmitting}
                  size='lg'
                  onClick={submitForm}
                >
                  {t('jobs.application.submit')}
                </Button>
                {isSubmitting && (
                  <LoaderWrapper>
                    <LoaderSpinner />
                  </LoaderWrapper>
                )}
              </FormFooter>
            </form>
          )}
        </Container>
      </BlockWrapper>
    </Page>
  );
};

export default JobApplicationTemplate;

export const jobApplicationPageQuery = graphql`
  query jobApplicationPageQuery($locale: String, $job_id: String) {
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allVacanciesJson(filter: { code: { eq: $job_id } }) {
      nodes {
        languageId
        code
        beginPublication
        title
        description
        type
        contractType
        category
      }
    }
    allContentfulJobListingBlock {
      nodes {
        node_locale
        privacyPolicyLink {
          ...LinkFragment
        }
      }
    }
  }
`;
